import "./Header.css";
import { Typography } from "@mui/material";
import { buttonVariants, buttonSizes } from "../../Shared/Components/Buttons/ButtonEnums";
import BasicButton from "../../Shared/Components/Buttons/BasicButton";
import { useNavigate } from 'react-router-dom';
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import { useState } from "react";

const Header = (props) => {
    const [showMenu, setShowMenu] = useState(false);
    let navigate = useNavigate();
    const logo = window.location.origin + "/Images/Logos/logo_large.png";

    const loginClicked = () => {
        navigate("/login", { replace: true });
    }

    const titleClicked = () => {
        navigate("/");
    }

    const showUserMenu = () => {
        setShowMenu(true);
    }

    return (
        <div>
            <div className="header-container">
                <div className="title" onClick={titleClicked}>
                    <img src={logo} width="45" height="45"/>
                    <div className="logo-text">TRAN TECH SOLUTIONS</div>
                </div>
                {/* <Typography className="title" variant="h5" fontWeight="bold" onClick={titleClicked}>Tran Tech Solutions</Typography> */}
                {!props.loggedIn 
                ?
                <div className="header-right-container">
                    <BasicButton variant={buttonVariants.contained} size={buttonSizes.medium} text="Login" 
                        onClick={loginClicked} color="primary"/>
                </div>
                :
                <div className="header-right-container">
                    <Typography className="title" variant="h6" onClick={showUserMenu}>{props.firstName} {props.lastName}</Typography>
                </div>   
                }
            </div>
            {showMenu && <HeaderMenu closeMenu={setShowMenu} setLogggedIn={props.setLogggedIn}/>}
        </div>
    )
}

export default Header;